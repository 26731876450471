<template>
  <div class="otherOrderDelivery">
    <p class="title">订单发货</p>
    <div class="content">
      <div class="btnBox">
        <!-- <el-button type="primary" @click="searchBtn('orders')">搜索订单</el-button>
        <el-button type="primary" @click="searchBtn('dispatchs')">搜索发货单</el-button> -->
        <!-- <el-button type="primary" @click="refresh">刷新发货单</el-button> -->
      </div>
      <el-table :data="tableData" stripe height="640" v-loading="loading">
        <el-table-column
          prop="order_code"
          label="订单编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="dispatch_code"
          label="发货单号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="customer_name"
          label="经销商"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="receive_man"
          label="收件人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="order_date"
          label="下单时间"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="doSomeThing(scope.row)"
            >
              查看
            </el-button>
            <el-button
              type="success"
              size="small"
              @click="transfer_single_dispatchFun(scope.row)"
            >
              刷新
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="el_pagination_style">
        <el-pagination
          :hide-on-single-page="total <= 0"
          background
          layout="prev, pager, next,jumper"
          :total="total"
          :current-page.sync="page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import apiDetail from '@/api/other.js'
import { Message } from 'element-ui'
export default {
  data() {
    return {
      loading: false,
      tableData: [{}],
      size: 10,
      total: 0,
      page: 1,
    }
  },
  mounted() {
    this.order_dispatch_listFun()
  },
  methods: {
    // 刷新
    refresh() {
      this.page = 1
      this.order_dispatch_listFun()
    },
    // 订单发货列表
    order_dispatch_listFun() {
      let param = {
        user_id: JSON.parse(localStorage.getItem('userInfo')).user_id,
        size: this.size,
        page: this.page,
      }
      apiDetail.order_dispatch_list(param).then((res) => {
        this.tableData = res.data.data
        this.total = res.data.count
      })
    },
    //  翻页
    handleCurrentChange(value) {
      this.page = value
      this.order_dispatch_listFun()
    },

    // 处理
    doSomeThing(row) {
      this.$router.push({
        path: '/orderDeliveryDetail',
        query: { dispatch_id: row.dispatch_id },
      })
    },

    // 刷新发货单
    transfer_single_dispatchFun(row) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      apiDetail
        .transfer_single_dispatch({
          dispatch_id: row.dispatch_id,
          is_refresh: 0,
        })
        .then((res) => {
          if (res == 200) {
            Message.success('刷新成功')
            loading.close()
          } else {
            loading.close()
          }
        })
    },

    // 搜索按钮
    searchBtn(value) {
      // localStorage.setItem('mode', value)
      sessionStorage.setItem('mode', value)

      this.$router.push({ path: 'SearchPage' })
    },
  },
}
</script>
<style lang="scss" scoped>
.otherOrderDelivery {
  margin: 20px;
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #111111;
    margin-bottom: 20px;
  }
  .content {
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    .btnBox {
      text-align: right;
      margin-bottom: 20px;
    }
  }
}
</style>